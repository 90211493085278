.modal {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.4);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    opacity: 0;
    pointer-events: none;
    padding: 5 20px;
}
@media only screen and (max-width: 800px) {
    .modal__content {
        margin: 0 20px;
    }
}
.modal.active {
    opacity: 1;
    pointer-events: all;
}
.modal__content {
    padding: 13px;
    border-radius: 12px;
    background-color: white;
    transform: scale(0.5);
    transition: 0.4s all;
}
.modal__content.active {
    transform: scale(1);
}

@media only screen and (max-width: 800px) {
    .modal__content {
        padding: 10px;
    }
}
