@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300..700&family=Roboto+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /* Calendar */
    --fc-small-font-size: 0.85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: hsla(0, 0%, 82%, 0.3);
    --fc-neutral-text-color: grey;
    --fc-border-color: #ddd;
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #5360e6;
    --fc-button-border-color: #5360e6;
    --fc-button-hover-bg-color: #3e4ce7;
    --fc-button-hover-border-color: #3e4ce7;
    --fc-button-active-bg-color: #3e4ce7;
    --fc-button-active-border-color: #3e4ce7;
    --fc-more-link-bg-color: #3788d8;
    --fc-more-link-text-color: white;
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
    --fc-non-business-color: hsla(0, 0%, 84%, 0.3);
    --fc-bg-event-color: #8fdf82;
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, 0.3);
    --fc-today-bg-color: rgba(255, 220, 40, 0.15);
    --fc-now-indicator-color: #5360e6;
}

*.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
}

.fc .fc-button-primary:focus {
    box-shadow: none;
}

.fc-header-toolbar {
    font-size: 16px;
}

@media (max-width: 830px) {
    .fc-header-toolbar {
        font-size: 10px;
    }
}
@media (max-width: 1000px) {
    .fc-header-toolbar {
        font-size: 13px;
    }
}

@media (max-width: 550px) {
    .fc-header-toolbar {
        font-size: 8px;
    }
}

.App {
    display: flex;
}

.my-fonts {
    font-family: 'Nunito Sans', sans-serif;
}

.button-animation {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.button-animation.active {
    background-color: #fff;
    color: #5460e6;
}

@keyframes slideRightToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-35px);
    }
}

.animate-slide {
    animation: slideRightToLeft 1s ease-in-out infinite alternate;
}

.container_event_child_break {
    border-left: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    background: rgb(255, 255, 255);
    background: linear-gradient(150deg,
            rgba(255, 255, 255) 0%,
            #fe5c5c 1%,
            rgba(255, 255, 255) 2%,
            rgba(255, 255, 255) 3%,
            rgba(255, 255, 255) 4%,
            rgba(255, 255, 255) 5%,
            #fe5c5c 6%,
            rgba(255, 255, 255) 7%,
            rgba(255, 255, 255) 8%,
            rgba(255, 255, 255) 9%,
            rgba(255, 255, 255) 10%,
            #fe5c5c 11%,
            rgba(255, 255, 255) 12%,
            rgba(255, 255, 255) 13%,
            rgba(255, 255, 255) 14%,
            rgba(255, 255, 255) 15%,
            #fe5c5c 16%,
            rgba(255, 255, 255) 17%,
            rgba(255, 255, 255) 18%,
            rgba(255, 255, 255) 19%,
            rgba(255, 255, 255) 20%,
            #fe5c5c 21%,
            rgba(255, 255, 255) 22%,
            rgba(255, 255, 255) 23%,
            rgba(255, 255, 255) 24%,
            rgba(255, 255, 255) 25%,
            #fe5c5c 26%,
            rgba(255, 255, 255) 27%,
            rgba(255, 255, 255) 28%,
            rgba(255, 255, 255) 29%,
            rgba(255, 255, 255) 30%,
            #fe5c5c 31%,
            rgba(255, 255, 255) 32%,
            rgba(255, 255, 255) 33%,
            rgba(255, 255, 255) 34%,
            rgba(255, 255, 255) 35%,
            #fe5c5c 36%,
            rgba(255, 255, 255) 37%,
            rgba(255, 255, 255) 38%,
            rgba(255, 255, 255) 39%,
            rgba(255, 255, 255) 40%,
            #fe5c5c 41%,
            rgba(255, 255, 255) 42%,
            rgba(255, 255, 255) 43%,
            rgba(255, 255, 255) 44%,
            rgba(255, 255, 255) 45%,
            #fe5c5c 46%,
            rgba(255, 255, 255) 47%,
            rgba(255, 255, 255) 48%,
            rgba(255, 255, 255) 49%,
            rgba(255, 255, 255) 50%,
            #fe5c5c 51%,
            rgba(255, 255, 255) 52%,
            rgba(255, 255, 255) 53%,
            rgba(255, 255, 255) 54%,
            rgba(255, 255, 255) 55%,
            #fe5c5c 56%,
            rgba(255, 255, 255) 57%,
            rgba(255, 255, 255) 58%,
            rgba(255, 255, 255) 59%,
            rgba(255, 255, 255) 60%,
            #fe5c5c 61%,
            rgba(255, 255, 255) 62%,
            rgba(255, 255, 255) 63%,
            rgba(255, 255, 255) 64%,
            rgba(255, 255, 255) 65%,
            #fe5c5c 66%,
            rgba(255, 255, 255) 67%,
            rgba(255, 255, 255) 68%,
            rgba(255, 255, 255) 69%,
            rgba(255, 255, 255) 70%,
            #fe5c5c 71%,
            rgba(255, 255, 255) 72%,
            rgba(255, 255, 255) 73%,
            rgba(255, 255, 255) 74%,
            rgba(255, 255, 255) 75%,
            #fe5c5c 76%,
            rgba(255, 255, 255) 77%,
            rgba(255, 255, 255) 78%,
            rgba(255, 255, 255) 79%,
            rgba(255, 255, 255) 80%,
            #fe5c5c 81%,
            rgba(255, 255, 255) 82%,
            rgba(255, 255, 255) 83%,
            rgba(255, 255, 255) 84%,
            rgba(255, 255, 255) 85%,
            #fe5c5c 86%,
            rgba(255, 255, 255) 87%,
            rgba(255, 255, 255) 88%,
            rgba(255, 255, 255) 89%,
            rgba(255, 255, 255) 90%,
            #fe5c5c 91%,
            rgba(255, 255, 255) 92%,
            rgba(255, 255, 255) 93%,
            rgba(255, 255, 255) 94%,
            rgba(255, 255, 255) 95%,
            #fe5c5c 96%,
            rgba(255, 255, 255) 97%,
            rgba(255, 255, 255) 98%,
            rgba(255, 255, 255) 99%,
            rgba(255, 255, 255) 100%);
}

.container_event_child_not_work {
    border-left: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    cursor: not-allowed;
    pointer-events: none;
    background: rgb(255, 255, 255);
    background: linear-gradient(150deg,
            rgba(255, 255, 255) 0%,
            #8f97f0 1%,
            rgba(255, 255, 255) 2%,
            rgba(255, 255, 255) 3%,
            rgba(255, 255, 255) 4%,
            rgba(255, 255, 255) 5%,
            #8f97f0 6%,
            rgba(255, 255, 255) 7%,
            rgba(255, 255, 255) 8%,
            rgba(255, 255, 255) 9%,
            rgba(255, 255, 255) 10%,
            #8f97f0 11%,
            rgba(255, 255, 255) 12%,
            rgba(255, 255, 255) 13%,
            rgba(255, 255, 255) 14%,
            rgba(255, 255, 255) 15%,
            #8f97f0 16%,
            rgba(255, 255, 255) 17%,
            rgba(255, 255, 255) 18%,
            rgba(255, 255, 255) 19%,
            rgba(255, 255, 255) 20%,
            #8f97f0 21%,
            rgba(255, 255, 255) 22%,
            rgba(255, 255, 255) 23%,
            rgba(255, 255, 255) 24%,
            rgba(255, 255, 255) 25%,
            #8f97f0 26%,
            rgba(255, 255, 255) 27%,
            rgba(255, 255, 255) 28%,
            rgba(255, 255, 255) 29%,
            rgba(255, 255, 255) 30%,
            #8f97f0 31%,
            rgba(255, 255, 255) 32%,
            rgba(255, 255, 255) 33%,
            rgba(255, 255, 255) 34%,
            rgba(255, 255, 255) 35%,
            #8f97f0 36%,
            rgba(255, 255, 255) 37%,
            rgba(255, 255, 255) 38%,
            rgba(255, 255, 255) 39%,
            rgba(255, 255, 255) 40%,
            #8f97f0 41%,
            rgba(255, 255, 255) 42%,
            rgba(255, 255, 255) 43%,
            rgba(255, 255, 255) 44%,
            rgba(255, 255, 255) 45%,
            #8f97f0 46%,
            rgba(255, 255, 255) 47%,
            rgba(255, 255, 255) 48%,
            rgba(255, 255, 255) 49%,
            rgba(255, 255, 255) 50%,
            #8f97f0 51%,
            rgba(255, 255, 255) 52%,
            rgba(255, 255, 255) 53%,
            rgba(255, 255, 255) 54%,
            rgba(255, 255, 255) 55%,
            #8f97f0 56%,
            rgba(255, 255, 255) 57%,
            rgba(255, 255, 255) 58%,
            rgba(255, 255, 255) 59%,
            rgba(255, 255, 255) 60%,
            #8f97f0 61%,
            rgba(255, 255, 255) 62%,
            rgba(255, 255, 255) 63%,
            rgba(255, 255, 255) 64%,
            rgba(255, 255, 255) 65%,
            #8f97f0 66%,
            rgba(255, 255, 255) 67%,
            rgba(255, 255, 255) 68%,
            rgba(255, 255, 255) 69%,
            rgba(255, 255, 255) 70%,
            #8f97f0 71%,
            rgba(255, 255, 255) 72%,
            rgba(255, 255, 255) 73%,
            rgba(255, 255, 255) 74%,
            rgba(255, 255, 255) 75%,
            #8f97f0 76%,
            rgba(255, 255, 255) 77%,
            rgba(255, 255, 255) 78%,
            rgba(255, 255, 255) 79%,
            rgba(255, 255, 255) 80%,
            #8f97f0 81%,
            rgba(255, 255, 255) 82%,
            rgba(255, 255, 255) 83%,
            rgba(255, 255, 255) 84%,
            rgba(255, 255, 255) 85%,
            #8f97f0 86%,
            rgba(255, 255, 255) 87%,
            rgba(255, 255, 255) 88%,
            rgba(255, 255, 255) 89%,
            rgba(255, 255, 255) 90%,
            #8f97f0 91%,
            rgba(255, 255, 255) 92%,
            rgba(255, 255, 255) 93%,
            rgba(255, 255, 255) 94%,
            rgba(255, 255, 255) 95%,
            #8f97f0 96%,
            rgba(255, 255, 255) 97%,
            rgba(255, 255, 255) 98%,
            rgba(255, 255, 255) 99%,
            rgba(255, 255, 255) 100%);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #ababab;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: #dedcdc;
}

* {
    scrollbar-color: #555 #f1f1f1;
}

/* Range */

.range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -7px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
}

.range-input input::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 3px solid #5260e7;
    background-color: #fff;
    pointer-events: auto;
    -webkit-appearance: none;
    cursor: pointer;
    transition-duration: 500ms;
}

.range-input input::-webkit-slider-thumb:hover {
    transform: scale(1.2);
    transition-property: all;
    transition-duration: 500ms;
}

.range-input input:disabled::-webkit-slider-thumb {
    border: 3px solid gainsboro;
}

@media only screen and (max-width: 600px) {
    .header-toolbar {
        flex-direction: column-reverse;
    }

    .header-toolbar>div {
        width: 100%;
        margin-bottom: 10px;
    }
}

.roboto-mono {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }